import React, { useState } from 'react';
import './ContactUsForm.css';
import { useTranslation } from 'react-i18next';

const ContactForm: React.FC = () => {
  const { t } = useTranslation();
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [successMessage, setSuccessMessage] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch('https://formspree.io/f/mbjnvyob', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Email sent successfully
        console.log('Email sent successfully');

        setFormData({
          name: '',
          email: '',
          message: '',
        });

        setSuccessMessage(true);
        // Hide success message after 3 seconds
        setTimeout(() => setSuccessMessage(false), 3000);

      } else {
        // Handle error
        console.error('Failed to send email');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
    <form className='form-container' onSubmit={handleSubmit}>
      <label>
        
        <input type="text" placeholder={t('emailFormName')} name="name" value={formData.name} onChange={handleChange} required />
      </label>
      <label>    
        <input type="email" placeholder={t('emailFormEmail')} name="email" value={formData.email} onChange={handleChange} required />
      </label>
      <label>    
        <textarea name="message" placeholder={t('emailFormBody')} value={formData.message} onChange={handleChange} required />
      </label>
      <button className="submitButton" type="submit">{t('emailFormSend')}</button>
      {successMessage && <div className="success-bubble">{t('emailFormSuccess')}</div>}
    </form>
    
    </>
  );
};

export default ContactForm;