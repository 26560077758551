import { t } from 'i18next';
import './Partnership.css';

const Partnership: React.FC = () => {
    
    return (
        <div className="partnership-container">
            <div className="partnership-item">
                <div className="partnership-text">{t('partnershipBasrat')}</div>
                <img
                    className="partnership-image"
                    src={`/assets/Images/Partnerships/Basrat.webp`}
                    alt={`BASRaT partnership`}
                />
            </div>
            <div className="partnership-item">
                <div className="partnership-text">{t('partnershipJHProMed')}</div>
                <img
                    className="partnership-image"
                    src={`/assets/Images/Partnerships/james_hallam_pro_med.webp`}
                    alt={`James Hallam ProMed partnership`}
                />
            </div>
        </div>
    );
};

export default Partnership;