import { useTranslation } from 'react-i18next';
import './AboutMe.css';

function AboutMe() {

  const { t } = useTranslation();

  return (
    <div className="about-me">
      <div className="info">
      <img className="about-me-img" src="/assets/Images/me.webp" alt="Julia" />
        <p>
        {t('aboutMe')}
        </p>
      </div>
    </div>
  );
}

export default AboutMe;