import React from 'react';
import './HoveringActions.css';

interface WhatsAppProps {
  phoneNumber: string;
}

const WhatsApp: React.FC<WhatsAppProps> = ({ phoneNumber }) => {
  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  return (
    <div className="hovering-button" onClick={handleClick}>
        <i className="fa-brands fa-whatsapp"></i>
    </div>
  );
};

export default WhatsApp;