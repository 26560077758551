import { useTranslation } from 'react-i18next';
import GoogleMap from '../GoogleMap/GoogleMap';
import './ContactUs.css';
import ContactForm from './ContactUsForm';
import { useState } from 'react';

function ContactUs() {
  const { t } = useTranslation();

  const hullAddress = "440 Beverley Road, Hull HU5 1LP";
  const yorkAddress = "Holgate Park Drive, York YO26 4NG";

  const hullMap = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2358.1890275763253!2d-0.36403875414396386!3d53.76832215351829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4878bf5ff67a6167%3A0xa8f8d8315c1e96c0!2sMusclEase%20Sport%20Rehab!5e0!3m2!1spl!2suk!4v1717204627674!5m2!1spl!2suk";
  const yorkMap = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d825.3972099309012!2d-1.1089560530031324!3d53.958965008533866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487931b12aad215b%3A0x3d43cfbc04797dc8!2sAtlas%20Physio%20-%20MSK%20and%20Sports%20Clinic%20York!5e0!3m2!1spl!2spl!4v1725062457274!5m2!1spl!2spl";

  const [activeAddress, setActiveAddress] = useState(hullAddress);
  const [activeMap, setActiveMap] = useState(hullMap);

  // Function to handle click and update active address
  const handleAddressClick = (address: string) => {

    if (activeAddress !== address) {
      setActiveAddress(address);

      if (address === hullAddress) {
        setActiveMap(hullMap);
      }
      if (address === yorkAddress) {
        setActiveMap(yorkMap);
      }
    }
  };

  return (
    <div className="contact-us">
      <div className="column left">

        <div className="contact-us-inner-container">
          <div className="contact-us-form">
            <h2><i className="fa-solid fa-address-book"></i>{t('contactUs')}</h2>
            <div>
              {t('contactUsInfo')}:
            </div>
            <div>
              <br />
              <a href="tel:+447584084915"><i className="fa-solid fa-phone"></i>+447584 084915</a>
              <br />
            </div>
            <div>
              <br />
              <div>{t('sendUsAnEmail')}:</div>
              <br />
              <ContactForm></ContactForm>
            </div>
          </div>
        </div>
      </div>
      <div className="column right">
        <div className="contact-us-inner-container">
          <div className="contact-us-address">
            <h2><i className="fa-solid fa-location-dot"></i>{t('findUs')}</h2>
            <div className="addresses">
              <div className="address-container">
                <i className="fa-solid fa-arrow-right-to-city"></i>
                <button className={activeAddress === hullAddress ? 'active ' : ''} onClick={() => handleAddressClick(hullAddress)}>{hullAddress}</button>
              </div>
              <div className="address-container">
                <i className="fa-solid fa-arrow-right-to-city"></i>
                <button className={activeAddress === yorkAddress ? 'active' : ''} onClick={() => handleAddressClick(yorkAddress)}>{yorkAddress}</button>
              </div>
            </div>
          </div>
        </div>
        <GoogleMap src={activeMap} />
      </div>
    </div>
  );
}

export default ContactUs;