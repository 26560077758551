import React from 'react';
import './VitDrips.css';
import { useTranslation } from 'react-i18next';

const VitDrips: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="drip-container">
            <div className="vit-drip-section-center">
                <div className="text-content-center la">
                    <p>{t("dripsIntro")}</p>
                </div>
            </div>

            <div className="vit-drip-container">
                <div className="vit-drip-item">
                    <div>
                        <h2>{t("vitaminDrips")}</h2>
                        <p>
                            {t("vitaminDripsDescription")}
                        </p>
                        <br />
                        <img src="/assets/Images/Drips/vit_drip1.webp" alt="Placeholder" className="drip-image" />

                        <br />
                        <h3>{t("keyComponentsAndProcess")}</h3>
                        <br />
                        <ol>
                            <li>
                                <strong>{t("intravenousAdministration")}</strong>
                                <ul>
                                    <li>{t("deliveryMethod")}</li>
                                    <li>{t("duration")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("commonIngredients")}</strong>
                                <ul>
                                    <li>{t("vitamins")}</li>
                                    <li>{t("minerals")}</li>
                                    <li>{t("aminoAcids")}</li>
                                    <li>{t("otherNutrients")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("personalizedFormulations")}</strong>
                                <ul>
                                    <li>{t("customBlends")}</li>
                                    <li>{t("standardMixes")}</li>
                                </ul>
                            </li>
                        </ol>
                        <br />
                        <h3>{t("benefitsOfVitaminDrips")}</h3>
                        <br />
                        <ol>
                            <li>
                                <strong>{t("enhancedNutrientAbsorption")}</strong>
                                <ul>
                                    <li>{t("directDelivery")}</li>
                                    <li>{t("bypassingDigestion")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("increasedEnergyLevels")}</strong>
                                <ul>
                                    <li>{t("immediateEffect")}</li>
                                    <li>{t("sustainedEnergy")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("improvedImmuneFunction")}</strong>
                                <ul>
                                    <li>{t("immuneSupport")}</li>
                                    <li>{t("preventiveCare")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("fasterRecovery")}</strong>
                                <ul>
                                    <li>{t("postIllness")}</li>
                                    <li>{t("athleticPerformance")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("hydration")}</strong>
                                <ul>
                                    <li>{t("rehydration")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("skinHealth")}</strong>
                                <ul>
                                    <li>{t("antiAging")}</li>
                                    <li>{t("complexion")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("detoxification")}</strong>
                                <ul>
                                    <li>{t("cleansing")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("mentalClarityAndMoodEnhancement")}</strong>
                                <ul>
                                    <li>{t("brainHealth")}</li>
                                    <li>{t("moodStabilization")}</li>
                                </ul>
                            </li>
                        </ol>
                        <br />
                        <h3>{t("considerations")}</h3>
                        <ul>
                            <li>{t("medicalSupervision")}</li>
                            <li>{t("sideEffects")}</li>
                            <li>{t("cost")}</li>
                        </ul>

                        <p>
                            {t("conclusion")}
                        </p>
                        <br />
                        <img src="/assets/Images/Drips/vit_drip2.webp" alt="Placeholder" className="drip-image" />
                    </div>
                </div>
                <div className="vit-drip-item">
                    <div>
                        <h2>{t("vitaminInjections")}</h2>
                        <p>{t("vitaminInjectionsDescription")}</p>
                        <br />
                        <img src="/assets/Images/Drips/vitamin_injection1.webp" alt="Placeholder" className="drip-image" />


                        <br />
                        <h3>{t("keyComponentsAndProcess")}</h3>
                        <br />
                        <ol>
                            <li>
                                <strong>{t("intramuscularAndSubcutaneousAdministration")}</strong>
                                <ul>
                                    <li>{t("deliveryMethodInjections")}</li>
                                    <li>{t("durationInjections")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("commonIngredientsInjections")}</strong>
                                <ul>
                                    <li>{t("vitaminB12")}</li>
                                    <li>{t("vitaminD")}</li>
                                    <li>{t("vitaminBComplex")}</li>
                                    <li>{t("vitaminC")}</li>
                                    <li>{t("biotin")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("personalizedFormulationsInjections")}</strong>
                                <ul>
                                    <li>{t("customDosesInjections")}</li>
                                    <li>{t("standardDosesInjections")}</li>
                                </ul>
                            </li>
                        </ol>
                        <br />
                        <img src="/assets/Images/Drips/vit_injection2.webp" alt="Placeholder" className="drip-image" />
                        <br />
                        <h3>{t("benefitsOfVitaminInjections")}</h3>
                        <br />
                        <ol>
                            <li>
                                <strong>{t("rapidAbsorption")}</strong>
                                <ul>
                                    <li>{t("directDeliveryInjections")}</li>
                                    <li>{t("bypassingDigestionInjections")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("increasedEnergyLevels")}</strong>
                                <ul>
                                    <li>{t("immediateEffectInjections")}</li>
                                    <li>{t("sustainedEnergyInjections")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("improvedMoodAndMentalClarity")}</strong>
                                <ul>
                                    <li>{t("cognitiveFunctionInjections")}</li>
                                    <li>{t("moodRegulationInjections")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("enhancedImmuneFunction")}</strong>
                                <ul>
                                    <li>{t("immuneSupportInjections")}</li>
                                    <li>{t("preventiveCareInjections")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("supportForSpecificHealthConditions")}</strong>
                                <ul>
                                    <li>{t("anemia")}</li>
                                    <li>{t("boneHealthInjections")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("improvedMetabolicFunction")}</strong>
                                <ul>
                                    <li>{t("metabolismBoostInjections")}</li>
                                    <li>{t("weightManagementInjections")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("enhancedPhysicalPerformance")}</strong>
                                <ul>
                                    <li>{t("athleticPerformanceInjections")}</li>
                                    <li>{t("muscleFunctionInjections")}</li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                <strong>{t("betterSkinHairAndNailHealth")}</strong>
                                <ul>
                                    <li>{t("aestheticBenefitsInjections")}</li>
                                    <li>{t("collagenProductionInjections")}</li>
                                </ul>
                            </li>
                        </ol>
                        <br />
                        <h3>{t("considerations")}</h3>
                        <ul>
                            <li>{t("medicalSupervisionInjections")}</li>
                            <li>{t("sideEffectsInjections")}</li>
                            <li>{t("frequencyInjections")}</li>
                        </ul>
                        <p>{t("conclusionInjections")}</p>
                    </div>
                </div>
            </div>


            <div className="vit-drip-container">

                <div className="vit-drip-item_long">
                    <div className="card-container">
                        <div className="card">
                            <img src="/assets/Images/Drips/Options/myers_cocktail.webp" alt="Myers Cocktail" className="card-image" />
                            <div className="card-content">
                                <h2>Myers Cocktail</h2>
                                <p>£120 / £170</p>
                                <p>{t("myers_cocktail")}</p>
                            </div>
                        </div>

                        <div className="card">
                            <img src="/assets/Images/Drips/Options/beauty_glow.webp" alt="Beauty Glow" className="card-image" />
                            <div className="card-content">
                                <h2>Beauty Glow</h2>
                                <p>£140 / £190</p>
                                <p>{t("beauty_glow")}</p>
                            </div>
                        </div>

                        <div className="card">
                            <img src="/assets/Images/Drips/Options/cinderella_drip.webp" alt="Cinderella Drip" className="card-image" />
                            <div className="card-content">
                                <h2>Cinderella Drip</h2>
                                <p>£180 / £230</p>
                                <p>{t("cinderella_drip")}</p>
                            </div>
                        </div>

                        <div className="card">
                            <img src="/assets/Images/Drips/Options/gym_bunny.webp" alt="Gym Bunny" className="card-image" />
                            <div className="card-content">
                                <h2>Gym Bunny</h2>
                                <p>£170 / £220</p>
                                <p>{t("gym_bunny")}</p>
                            </div>
                        </div>

                        <div className="card">
                            <img src="/assets/Images/Drips/Options/ultimate_hydration.webp" alt="Ultimate Hydration" className="card-image" />
                            <div className="card-content">
                                <h2>Ultimate Hydration</h2>
                                <p>£170 / £220</p>
                                <p>{t("ultimate_hydration")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VitDrips;