import { useTranslation } from 'react-i18next';
import './Reviews.css';


const Reviews: React.FC = () => {


    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.language;

    console.log(currentLanguage);
    if (currentLanguage === 'pl') {
        return (
            <>
                <div className="reviews-container">
                    <div className="review-header">
                        {t('reviewsSectionTitle')}
                    </div>
                    <div>
                        <img
                            className="review"
                            src={`/assets/Images/Reviews/review1_pl.webp`}
                            alt={t('reviewsFirstAlt')} />
                        <img
                            className="review"
                            src={`/assets/Images/Reviews/review2_pl.webp`}
                            alt={t('reviewsSecondAlt')} />
                        <img
                            className="review"
                            src={`/assets/Images/Reviews/review3_pl.webp`}
                            alt={t('reviewsThirdAlt')} />
                        <img
                            className="review"
                            src={`/assets/Images/Reviews/review4_pl.webp`}
                            alt={t('reviewsFourthAlt')} />
                    </div>
                </div>
            </>
        );
    }
    else {
        return (
            <>
                <div className="reviews-container">
                    <div className="review-header">
                        {t('reviewsSectionTitle')}
                    </div>
                    <div>
                        <img
                            className="review"
                            src={`/assets/Images/Reviews/review1_en.webp`}
                            alt={t('reviewsFirstAlt')} />
                        <img
                            className="review"
                            src={`/assets/Images/Reviews/review2_en.webp`}
                            alt={t('reviewsSecondAlt')} />
                        <img
                            className="review"
                            src={`/assets/Images/Reviews/review3_en.webp`}
                            alt={t('reviewsThirdAlt')} />
                        <img
                            className="review"
                            src={`/assets/Images/Reviews/review4_en.webp`}
                            alt={t('reviewsFourthAlt')} />
                    </div>
                </div>
            </>
        );
    }
};

export default Reviews;