import React from 'react';
import './BloodTests.css';
import { useTranslation } from 'react-i18next';

const BloodTests: React.FC = () => {
    const { t } = useTranslation();
    
    return (
        <div className="blood-tests-container">
            <div className="blood-tests-section-wide">            
                <img src="/assets/Images/Blood/blood1.webp" alt="Placeholder" className="placeholder-image" />
                <div className="text-content">
                    <h3>{t("bloodP2_Header")}</h3>
                    <p>{t("bloodP1")}</p>
                    <br/>
                    <ul>
                        <li><strong>{t("bloodP2_li1")}</strong></li>
                        <p>{t("bloodP2_li2_c1")}</p>
                        <p>{t("bloodP2_li2_c2")}</p>
                        <br/>
                        <li><strong>{t("bloodP2_li4")}</strong></li>
                        <br />
                        <p><strong>{t("bloodP2_li5t")}</strong></p>
                        <p>{t("bloodP2_li15_d")}</p>
                        <br />
                        <p><strong>{t("bloodP2_li12t")}</strong></p>
                        <p>{t("bloodP2_li16_d")}</p>
                        <br />
                        <p><strong>{t("bloodP2_li13t")}</strong></p>
                        <p>{t("bloodP2_li17_d")}</p>
                        <br />
                        <p><strong>{t("bloodP2_li14")}</strong></p>
                    </ul>
                </div>
            </div>

            <div className="blood-tests-section">
                <div className="text-content">
                    <h3>{t("bloodP2_li15")}</h3>
                    <p>{t("bloodP2_li15_d")}</p>
                    <br/>
                    <ul>
                        <li>{t("bloodP2_li15_li1")}</li>
                        <li>{t("bloodP2_li15_li2")}</li>
                        <li>{t("bloodP2_li15_li3")}</li>
                        <li>{t("bloodP2_li15_li4")}</li>
                        <li>{t("bloodP2_li15_li5")}</li>
                        <li>{t("bloodP2_li15_li6")}</li>
                        <li>{t("bloodP2_li15_li7")}</li>
                        <li>{t("bloodP2_li15_li8")}</li>
                        <li>{t("bloodP2_li15_li9")}</li>
                        <li>{t("bloodP2_li15_li10")}</li>
                        <li>{t("bloodP2_li15_li11")}</li>
                        <li>{t("bloodP2_li15_li12")}</li>
                        <li>{t("bloodP2_li15_li13")}</li>
                        <li>{t("bloodP2_li15_li14")}</li>
                    </ul>
                </div>
                <img src="/assets/Images/Blood/blood2.webp" alt="Placeholder" className="placeholder-image" />
            </div>

            <div className="blood-tests-section-wide">
            <img src="/assets/Images/Blood/blood3.webp" alt="Placeholder" className="placeholder-image" />
                <div className="text-content">
                <h3>{t("bloodP2_li16")}</h3>
                    <p>{t("bloodP2_li16_d")}</p>
                    <br/>
                    <ul>
                        <li>{t("bloodP2_li16_li1")}</li>
                        <li>{t("bloodP2_li16_li2")}</li>
                        <li>{t("bloodP2_li16_li3")}</li>
                        <li>{t("bloodP2_li16_li4")}</li>
                        <li>{t("bloodP2_li16_li5")}</li>
                        <li>{t("bloodP2_li16_li6")}</li>
                        <li>{t("bloodP2_li16_li7")}</li>
                        <li>{t("bloodP2_li16_li8")}</li>
                    </ul>
                </div>
                
            </div>

            <div className="blood-tests-section">
                <div className="text-content">
                <h3>{t("bloodP2_li17")}</h3>
                    <p>{t("bloodP2_li17_d")}</p>
                    <br/>
                    <ul>
                        <li>{t("bloodP2_li17_li1")}</li>
                        <li>{t("bloodP2_li17_li2")}</li>
                        <li>{t("bloodP2_li17_li3")}</li>
                        <li>{t("bloodP2_li17_li4")}</li>
                        <li>{t("bloodP2_li17_li5")}</li>
                        <li>{t("bloodP2_li17_li6")}</li>
                        <li>{t("bloodP2_li17_li7")}</li>
                        <li>{t("bloodP2_li17_li8")}</li>
                        <li>{t("bloodP2_li17_li9")}</li>
                    </ul>
                </div>
                <img src="/assets/Images/Blood/blood4.webp" alt="Placeholder" className="placeholder-image" />
            </div>

            <div className="blood-tests-section-center">
                <div className="text-content">
                    <h3>{t("bloodP4_Header")}</h3>
                    <ul>
                        <li>{t("bloodP4_li1")}</li>
                    </ul>
                    <p><strong>{t("bloodP4_li4")}</strong></p>
                </div>


                <a href="/assets/Files/blood_tests.pdf" download>
                    <button className="brochure_download_button">{t("blood_download_eng")}</button>
                </a>

                <a href="/assets/Files/badania_krwi.pdf" download>
                    <button className="brochure_download_button">{t("blood_download_pl")}</button>
                </a>
            </div>
        </div>
    );
};

export default BloodTests;