import React from 'react';
import './Header.css';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

const Header: React.FC = () => {
    const { t } = useTranslation();
    const addressHull = encodeURIComponent("440 Beverley Rd, Hull HU5 1LP");
    const addressYork = encodeURIComponent("Atlas Physio, Holgate Park Drive, York YO26 4NG");
    const googleMapsUrlHull = `https://www.google.com/maps?q=${addressHull}`;
    const googleMapsUrlYork= `https://www.google.com/maps?q=${addressYork}`;

    const whatsAppHandleClick = () => {
        const url = `https://wa.me/+447584084915`;
        window.open(url, '_blank');
      };
    
    return (
        <div className='container'>
            <div className='contact-us-container'>
                <div>
                    <i className="fa-solid fa-phone"></i>
                    {t('callUs')}: <a href="tel:+447584084915">+447584 084915</a>
                </div>
                <div>
                    <i className="fa-solid fa-envelope"></i>
                    {t('emailUs')}: <a href="mailto:musclease.sportsrehab@gmail.com">musclease.sportsrehab@gmail.com</a>
                </div>
                <div>
                    <i className="fa-solid fa-location-dot"></i>
                    {t('findUs')}: <a href={googleMapsUrlHull} target="_blank" rel="noopener noreferrer">440 Beverley Rd, Hull HU5 1LP</a> | <a href={googleMapsUrlYork} target="_blank" rel="noopener noreferrer">Holgate Park Drive, York YO26 4NG</a>
                </div>
            </div>

            <div className='contact-us-container'>
                <div className="icon-container">
                    <a href="https://www.facebook.com/SportsRehab22" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook large-icon"></i></a>
                </div>
                <div className="icon-container">
                    <a href="https://www.instagram.com/musclease_rehabilitation_iv/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram large-icon"></i></a>
                </div>
                <div className="icon-container" onClick={whatsAppHandleClick}>
                    <i className="fa-brands fa-whatsapp large-icon"></i>
                </div>
                <div>
                    <a className="a-button" href="/appointment">{t('bookAppointment')}</a>
                </div>
                <div>
                <LanguageSwitcher />
                </div>
            </div>
        </div >
    );
};

export default Header;

