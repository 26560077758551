import { useTranslation } from 'react-i18next';
import './BookAppointment.css';
import { useState, useRef } from 'react';
import ContactForm from '../ContactUs/ContactUsForm';

function BookAppointmentComponent() {
  const { t } = useTranslation();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEmailFormOpen, setIsEmailFormOpen] = useState(false);

  // Create a ref for the bookings div
  const bookingsRef = useRef<HTMLDivElement>(null);

  // Scroll to the bookings section
  const scrollToBookings = () => {
    bookingsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
    setIsEmailFormOpen(false);
    scrollToBookings(); // Scroll when form is toggled
  };

  const toggleEmailForm = () => {
    setIsEmailFormOpen(!isEmailFormOpen);
    setIsFormOpen(false);
    scrollToBookings(); // Scroll when email form is toggled
  };

  return (
    <div>
      <div className='book-appointment-container'>
        <div className='book-appointment-item'>
          <h3>{t("hullAppointmentsTitle")}</h3>
          <a href="https://www.google.com/maps?q=440%20Beverley%20Rd%2C%20Hull%20HU5%201LP" target="_blank" rel="noopener noreferrer">440 Beverley Rd, Hull HU5 1LP</a>
          <br />
          <p>{t("hullBook1")}</p>
          <br />
          <p>{t("hullBook2")}<a href="tel:+447584084915">+447584 084915</a>,{t("hullBook3")}{t("hullBook4")}</p>
          <br />
          <div>
            <button className="bookHullButton" onClick={toggleForm}>{isFormOpen ? t("hideForm") : t("showForm")}</button>
            <button className="bookHullButton" onClick={toggleEmailForm}>{isEmailFormOpen ? t("hideEmailForm") : t("showEmailForm")}</button>
          </div>
        </div>
        <div className='book-appointment-item'>
          <h3>{t("yorkAppointmentsTitle")}</h3>
          <a href="https://www.google.com/maps?q=Atlas%20Physio%2C%20Holgate%20Park%20Drive%2C%20York%20YO26%204NG" target="_blank" rel="noopener noreferrer">Holgate Park Drive, York YO26 4NG</a>
          <br />
          <p>{t("yorkBook1")}</p>
          <br />
          <p>{t("yorkBook3")}<a href="tel:+447584084915">+447584 084915</a>{t("yorkBook4")}</p>
          <br />
          <div>
            <button className="bookHullButton" onClick={toggleEmailForm}>{isEmailFormOpen ? t("hideEmailForm") : t("showEmailForm")}</button>
          </div>
        </div>
      </div>
      <div id="bookings" ref={bookingsRef}>
        {isEmailFormOpen && (
          <div className='book-appointment-item email'>
            <h2><i className="fa-solid fa-address-book"></i>{t('contactUs')}</h2>
            <p>{t("hullBook1")}</p>
            <ContactForm></ContactForm>
          </div>
        )}
        {isFormOpen && (
          <div className="book-appointment">
            <iframe title="x" src="https://app.rehabguru.com/appointments/d569e55a994de812425aea8e57aebf5e%3Ae5eb39ba9a44deaafaa736ce12450cc2635a489e12168066114e3be7687ac9d1c3294d22ed42734b8edc05dbb06cf68c" className="rehabguru-iframe"></iframe>
          </div>
        )}
      </div>
    </div>
  );
}

export default BookAppointmentComponent;
