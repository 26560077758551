import React from 'react';
import './GoogleMap.css';

interface GoogleMapProps {
    src: string;
  }

const GoogleMap: React.FC<GoogleMapProps> = ({ src }) => {
    return (
        <div className="map-container">
            <iframe
                title="google_maps_location"
                className="map"
                src={src}
                loading="lazy">
            </iframe>
        </div>
    );
};

export default GoogleMap;

