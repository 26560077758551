import React from 'react';
import AboutMe from '../../Components/AboutMe/AboutMe';
import Partnership from '../../Components/Partnerships/Partnership';

const AboutMePage: React.FC = () => {
    return (
        <>
            <AboutMe></AboutMe>
            <Partnership></Partnership>
        </>
    );
}

export default AboutMePage;