import React from 'react';
import Reviews from '../../Components/Reviews/Reviews';
import AboutMe from '../../Components/AboutMe/AboutMe';
import Partnership from '../../Components/Partnerships/Partnership';
import BloodTests from '../../Components/BloodTests/BloodTests';
import BookAppointmentComponent from '../../Components/BookAppointment/BookAppointment';
import VitDrips from '../../Components/VitDrips/VitDrips';
import Physio from '../../Components/Physio/Physio';
import Header from '../../Components/Header/Header';
import ContactUs from '../../Components/ContactUs/ContactUs';

const Home: React.FC = () => {
  return (
    <div>
      <div className="displayMobile">
        <Header></Header>
      </div>
      <AboutMe></AboutMe>
      <Reviews></Reviews>
      <Physio></Physio>
      <BloodTests></BloodTests>
      <VitDrips></VitDrips>
      <BookAppointmentComponent></BookAppointmentComponent>
      <Partnership></Partnership>
      <ContactUs></ContactUs>
    </div>
  );
}

export default Home;