import React, { useState } from 'react';
import i18n from 'i18next';
import "./LanguageSwitcher.css"

const LanguageSwitcher: React.FC = () => {
    const [activeLanguage, setActiveLanguage] = useState<string>('en');

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setActiveLanguage(lng);
    };

    return (
        <div className='language-flags'>
            <span
                onClick={() => changeLanguage('en')}
                className={`no-margin-flag flag fi fi-gb ${activeLanguage === 'en' ? 'active-flag' : ''}`}>
            </span>
            <span
                onClick={() => changeLanguage('pl')}
                className={`flag fi fi-pl ${activeLanguage === 'pl' ? 'active-flag' : ''}`}>
            </span>
        </div>
    );
};

export default LanguageSwitcher;