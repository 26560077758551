import React from 'react';
import './Physio.css';
import { useTranslation } from 'react-i18next';

const Physio: React.FC = () => {
    const { t } = useTranslation();
    
    return (
        <div className="rehab-container">
            <div className="rehab-section-wide">            
                <img src="/assets/Images/Rehab/rehab_1.webp" alt="Placeholder" className="placeholder-image" />
                <div className="text-content">
                    <h3>{t("rehabTitle")}</h3>
                    <p>{t("rehabDesc")}</p>
                    <ul>
                        <li><strong>{t("rehabP1")}</strong></li>
                        <p>{t("rehabP1_li1")}</p>
                        <p>{t("rehabP1_li2")}</p>
                        <br />
                        <li><strong>{t("rehabP2")}</strong></li>
                        <p>{t("rehabP2_li1")}</p>
                        
                    </ul>
                </div>
            </div>

            <div className="rehab-section">
                <div className="text-content">
                    <h3>{t("rehabP3")}</h3>
                    <ul>
                        <li><strong>{t("rehabP4t")}</strong>{t("rehabP4")}</li>
                        <li><strong>{t("rehabP5t")}</strong>{t("rehabP5")}</li>
                        <li><strong>{t("rehabP6t")}</strong>{t("rehabP6")}</li>
                        <li><strong>{t("rehabP7t")}</strong>{t("rehabP7")}</li>
                        <li><strong>{t("rehabP8t")}</strong>{t("rehabP8")}</li>
                        <li><strong>{t("rehabP9t")}</strong>{t("rehabP9")}</li>
                        <li><strong>{t("rehabP10t")}</strong>{t("rehabP10")}</li>
                    </ul>
                </div>
                <img src="/assets/Images/Rehab/rehab_2.webp" alt="Placeholder" className="placeholder-image" />
            </div>

            <div className="rehab-section-wide">            
                <img src="/assets/Images/Rehab/rehab_3.webp" alt="Placeholder" className="placeholder-image" />
                <div className="text-content">
                    <ul>
                        <li><strong>{t("rehabP11")}</strong></li>
                        <p>{t("rehabP12")}</p>
                        <p>{t("rehabP13")}</p>
                        <p>{t("rehabP14")}</p>
                        <p>{t("rehabP15")}</p>

                        
                        <li><strong>{t("rehabP16")}</strong></li>
                        <p>{t("rehabP17")}</p>
                        <p>{t("rehabP18")}</p>

                        <li><strong>{t("rehabP19")}</strong></li>
                        <p>{t("rehabP20")}</p>
                        <p>{t("rehabP21")}</p>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Physio;