import React from 'react';
import './HoveringActions.css';


const BookAppointmentAction: React.FC = () => {
  return (
    <div className="hovering-button">
        <a href="/appointment"><i className="fa-solid fa-calendar-check"></i></a>
    </div>
  );
};

export default BookAppointmentAction;